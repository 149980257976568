import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Logs from "./pages/Logs";
import Billing from "./pages/Billing";
import Profile from "./pages/Profile";
import Upload from "./pages/Upload";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import ResetPassword from "./pages/ResetPassword";
import ProtectedRoute from "./components/ProtectedRoute";
import PaymentConfirmation from "./pages/PaymentConfirmation";
import { Crisp } from "crisp-sdk-web";
import { useEffect } from "react";
import { initializeGTM } from './components/gtm';
import MaintenancePage from "./pages/Maintenance";

function App() {

  function isDesktop() {
    const userAgent = navigator.userAgent;
    const mobileKeywords = [
      "Android",
      "webOS",
      "iPhone",
      "iPad",
      "iPod",
      "BlackBerry",
      "Windows Phone",
    ];

    for (let keyword of mobileKeywords) {
      if (userAgent.includes(keyword)) {
        return false;
      }
    }

    return true;
  }

  useEffect(() => {
    Crisp.configure("908df5a0-1f32-4784-9eee-7541e8401834");
    initializeGTM();
  }, []);

  if (!isDesktop()) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <h1 className="text-2xl font-bold text-center">
          Please use dashboard from desktop
          <br />
          <span className="text-base">or refresh the page if you are on</span>
        </h1>
      </div>
    );
  }
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/register" element={<SignUp />} />
          <Route path="/login" element={<SignIn />} />
          <Route
            path="/reset-password"
            element={
              <ProtectedRoute>
                <ResetPassword />
              </ProtectedRoute>
            }
          />
          <Route
            path="/upload"
            element={
              <ProtectedRoute>
                <Main>
                  <Upload />
                </Main>
              </ProtectedRoute>
            }
          />
          <Route
            path="/logs"
            element={
              <ProtectedRoute>
                <Main>
                  <Logs />
                </Main>
              </ProtectedRoute>
            }
          />
          <Route
            path="/billing"
            element={
              <ProtectedRoute>
                <Main>
                  <Billing />
                </Main>
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Main>
                  <Profile />
                </Main>
              </ProtectedRoute>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Main>
                  <Home /> 
                </Main>
              </ProtectedRoute>
            }
          />
          <Route
            path="/payment-confirm/:type/:chargeid?"
            element={
              <ProtectedRoute>
                <PaymentConfirmation />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </div>
  );
  // return (
  //   <div className="App">
  //     <Router>
  //       <Routes>
  //         <Route path="/register" element={<MaintenancePage/>} />
  //         <Route path="/login" element={<MaintenancePage/>} />
  //         <Route
  //           path="/reset-password"
  //           element={
  //             <ProtectedRoute>
  //               <MaintenancePage />
  //             </ProtectedRoute>
  //           }
  //         />
  //         <Route
  //           path="/upload"
  //           element={
  //             <ProtectedRoute>
  //               <MaintenancePage/>
  //             </ProtectedRoute>
  //           }
  //         />
  //         <Route
  //           path="/logs"
  //           element={
  //             <ProtectedRoute>
  //               <MaintenancePage/>
  //             </ProtectedRoute>
  //           }
  //         />
  //         <Route
  //           path="/billing"
  //           element={
  //             <ProtectedRoute>
  //               <MaintenancePage/>
  //             </ProtectedRoute>
  //           }
  //         />
  //         <Route
  //           path="/profile"
  //           element={
  //             <ProtectedRoute>
  //               <MaintenancePage/>
  //             </ProtectedRoute>
  //           }
  //         />
  //         <Route
  //           path="/"
  //           element={
  //             <ProtectedRoute>
  //               <MaintenancePage/>
  //             </ProtectedRoute>
  //           }
  //         />
  //         <Route
  //           path="/payment-confirm/:type/:chargeid?"
  //           element={
  //             <ProtectedRoute>
  //               <PaymentConfirmation />
  //             </ProtectedRoute>
  //           }
  //         />
  //       </Routes>
  //     </Router>
  //   </div>
  // );
}

export default App;
